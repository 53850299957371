<template>
  <div class="dialog-container">
    <el-dialog
      :title="$t('systemWarning')"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      append-to-body
      width="484px">
      <div class="dialog-wrap">
        <h2>{{$t('warning3')}}</h2>
        <div style="margin-top:13px">{{$t('warning4')}}</div>
        <!-- <div>{{$t('warning5')}}</div> -->
        <!-- <div>如设置内容还需要使用，请点击右上角的
          <img class="images" src="../../../../assets/images/back_close.png" alt="" srcset="">关闭本弹窗，
        </div>
        <div>并进行导出备份操作！</div> -->
      </div>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import mixin from '@/mixins/dialogMixin'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      this.$emit('systemSure')
    },
  },
}
</script>
<style lang="less" scoped>
@import url('../../../../../src/assets/styles/mixin.scoped.less');
.dialog-wrap{
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height:20px;
  margin-left: 48px;
  // align-items: center;
  h2 {
    margin-bottom: 10px;
    font-weight: 700;
  }
  p {
    margin-bottom: 10px;
    display: inline-block;
  }
  .images {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
