import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.weeks = [
        { value: '1', text: '周一' },
        { value: '2', text: '周二' },
        { value: '3', text: '周三' },
        { value: '4', text: '周四' },
        { value: '5', text: '周五' },
        { value: '6', text: '周六' },
        { value: '7', text: '周日' },
      ]
      this.week = '1'
      this.weekName = '周一'
      this.tableData = []
      console.log(this.tableData);
    } else if (type === 'rule') {
      this.price = [
        {
          required: true,
          message: `${window.v.$t('QTX')} ${window.v.$t('price')}`,
          trigger: 'blur',
        },
      ]
    }
  }
}
export default searchFrom
